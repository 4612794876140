import './Error.scss';
import React from 'react';

const Error = () => (
  <div>
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 className="h2">Whoops</h1>
      <p>Something goes wrong</p>
    </div>
  </div>
);

export default Error;
