import MainLayout from 'layouts/MainLayout';
import { RouteObject, createBrowserRouter } from 'react-router-dom';
import MainLayoutProvider from 'providers/MainLayoutProvider';
import App from 'App';
import PATHS from './paths';
import SignIn from './pages/authentication/SignIn';
import SignOut from './pages/authentication/SignOut';
import { MAIN_LAYOUT_ROUTES } from './routes/main-layout-routes';
import Error from './pages/error/Error';

const routes: RouteObject[] = [
  {
    element: <App />,
    errorElement: <Error />,
    children: [
      {
        path: PATHS.HOME,
        element: (
          <MainLayoutProvider>
            <MainLayout />
          </MainLayoutProvider>
        ),
        children: MAIN_LAYOUT_ROUTES
      },
      {
        path: PATHS.SIGN_IN,
        element: <SignIn />
      },
      {
        path: PATHS.SIGN_OUT,
        element: <SignOut />
      }
    ]
  }
];

export const router = createBrowserRouter(routes);

export default routes;
