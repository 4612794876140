import './OrderForm.scss';
import React, { useEffect, useState } from 'react';
import Products from './Products/Products';
import Shipping from './Shipping/Shipping';
import Confirm from './Confirm/Confirm';
import Designs from './Designs/Designs';
import Stepper from '../../../components/common/Stepper/Stepper';
import {
  confirmValid,
  designsValid,
  productsValid,
  shipmentValid
} from './OrderFormFunctions';
import { api } from '../../../api/Api';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import PATHS from '../../../paths';
import Button from '../../../components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport, faRefresh } from '@fortawesome/free-solid-svg-icons';
import IOrderResponse from '../../../models/responces/IOrderResponse';
import IOrderExtended from '../../../models/restful/IOrderExtended';

interface IOrderFormProps {}

type TStep = 'products' | 'design' | 'shipping' | 'confirm' | 'view';

const OrderForm: React.FunctionComponent<IOrderFormProps> = () => {
  const { name } = useParams();
  const [order, setOrder] = useState<IOrderExtended>({} as IOrderExtended);

  const navigate = useNavigate();

  const updateOrderFromServer = () => {
    api.read('Order', '' + name, 'Name').then(resp => {
      if (!resp.success || !resp.item) {
        console.log(resp);
        // navigate(generatePath(PATHS.ORDERS));
        return;
      }
      const orderResponse = resp as IOrderResponse;
      const o = orderResponse.item;
      let defaultStep: TStep = 'products';
      if (productsValid(o)) {
        defaultStep = 'design';
        if (designsValid(o)) {
          defaultStep = 'shipping';
          if (shipmentValid(o)) defaultStep = 'confirm';
        }
      }

      setOrder(o);
      setStep(defaultStep);
    });
  };

  useEffect(() => {
    updateOrderFromServer();
  }, []);

  const [step, setStep] = useState<TStep>('products');
  const [showRequired, setShowRequired] = useState<number>(0);

  if (!order || !order.Name) return null;

  const getProgress = () => {
    if (step === 'products') return 1;
    if (step === 'design') return 34;
    if (step === 'shipping') return 67;
    if (step === 'confirm') return 99;
    return 0;
  };
  const getProgressStep = () => {
    if (step === 'products') return 1;
    if (step === 'design') return 2;
    if (step === 'shipping') return 3;
    if (step === 'confirm') return 4;
    return 1;
  };
  const setProgressStep = (idx: number) => {
    let st = '';
    if (idx === 1) {
      st = 'products';
    }
    if (idx === 2) {
      if (productsValid(order)) {
        if (showRequired > 0) setShowRequired(0);
        st = 'design';
      } else setShowRequired(1);
    }
    if (idx === 3) {
      if (designsValid(order)) {
        if (showRequired > 0) setShowRequired(0);
        st = 'shipping';
      } else setShowRequired(2);
    }
    if (idx === 4) {
      if (shipmentValid(order)) {
        if (showRequired > 0) setShowRequired(0);
        st = 'confirm';
      } else setShowRequired(3);
    }
    if (st) setStep(st as TStep);
  };

  const getButtons = () => {
    return (
      <div className="row justify-content-center">
        <div className="col-3 mb-3 pt-3">
          <button
            className="btn btn-info btn-sm"
            disabled={getProgressStep() <= 1}
            onClick={() =>
              getProgressStep() > 1
                ? setProgressStep(getProgressStep() - 1)
                : null
            }
          >
            Prev
          </button>
        </div>
        <div className="col-5 mb-3">
          <Stepper
            progress={getProgress()}
            steps={[
              {
                idx: 1,
                title: 'Products',
                hasError: !productsValid(order),
                onClick: () => setProgressStep(1)
              },
              {
                idx: 2,
                title: 'Designs',
                hasError: !designsValid(order),
                onClick: () => setProgressStep(2)
              },
              {
                idx: 3,
                title: 'Shipping',
                hasError: !shipmentValid(order),
                onClick: () => setProgressStep(3)
              },
              {
                idx: 4,
                title: 'Confirm',
                hasError: !confirmValid(order),
                onClick: () => setProgressStep(4)
              }
            ]}
          />
        </div>
        <div className="col-4 mb-3 pt-3 text-end">
          {getProgressStep() < 4 && (
            <button
              className="btn btn-info btn-sm"
              onClick={() =>
                getProgressStep() < 4
                  ? setProgressStep(getProgressStep() + 1)
                  : null
              }
            >
              Next
            </button>
          )}
          {getProgressStep() === 4 && (
            <button
              className="btn btn-success btn-sm me-3"
              onClick={() => confirmAndPostOrder()}
            >
              Confirm
            </button>
          )}
        </div>
      </div>
    );
  };

  const confirmAndPostOrder = () => {
    console.log('confirmAndPostOrder');
    api.patch('Order', 'confirm', order).then(resp => {
      console.log(resp);
      // navigate(generatePath(PATHS.ORDERS));
    });
  };

  return (
    <div className="order-form-cont">
      <div className="row">
        <div className="col-6">
          <h2 className="mb-4">Order {order.Name}</h2>
        </div>
        <div className="col-6">
          <div className="ms-xxl-auto text-end">
            <Button
              variant="link"
              className="text-900 me-4 px-0"
              onClick={() => updateOrderFromServer()}
            >
              <FontAwesomeIcon icon={faRefresh} className="fs-9 me-2" />
              Reload
            </Button>
            <Button
              variant="link"
              className="text-900 me-4 px-0"
              onClick={() => navigate(generatePath(PATHS.ORDERS))}
            >
              <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
              Save and close
            </Button>
            <Button
              variant="link"
              className="text-900 me-4 px-0"
              onClick={() =>
                getProgressStep() < 4
                  ? setProgressStep(getProgressStep() + 1)
                  : null
              }
            >
              <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
              Save
            </Button>
          </div>
        </div>
      </div>
      <hr />
      {getButtons()}
      <hr />
      {step === 'products' && (
        <Products order={order} showRequired={showRequired === 1} />
      )}
      {step === 'design' && (
        <Designs order={order} showRequired={showRequired === 2} />
      )}
      {step === 'shipping' && (
        <Shipping order={order} showRequired={showRequired === 3} />
      )}
      {step === 'confirm' && <Confirm order={order} />}
      <hr />
      {getButtons()}
      <hr />
    </div>
  );
};

export default OrderForm;
